import { FORM_IDS } from "components/data/landing-page-data";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { styled } from "linaria/react";
import React from "react";
import videoSrc from "./assets/surveillance-video-2.webm";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";

const HeroContainer = styled(SectionContainer)`
  background: #e0f0fb;
  padding: 2.5rem 1.25rem;
  overflow: visible;
  min-height: 740px;
  @media (max-width: 600px) {
    padding: 2.5rem 0 0 0;
  }
`;

const HeroSectionInner = styled(SectionInner)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  max-width: 673px;
  flex-shrink: 0;
  object-fit: contain;
  object-position: top;
`;

const HeroLeft = styled(SectionCol)`
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 600px) {
    padding: 0 1.25rem;
  }
`;

const HeroRight = styled(SectionCol)`
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
`;

const CenterTextContainer = styled(TextContainer)`
  text-align: center;
  align-items: center;
  max-width: 673px;
  @media (max-width: 600px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const SurveillanceHero = () => {
  const { isMobile } = useIsMobile(820);
  return (
    <HeroContainer id="top">
      <HeroSectionInner>
        <HeroLeft>
          <StyledVideo autoPlay loop muted playsInline src={videoSrc} />
          <CenterTextContainer>
            <FancyPageTitle>
              Smart Surveillance for All Your Business Properties
            </FancyPageTitle>
            <MainParagraph>
              Monitor all locations, automatically identify security events, and
              resolve incidents in real time with Rhombus' AI and cloud-based
              solution.
            </MainParagraph>
          </CenterTextContainer>
        </HeroLeft>
        <HeroRight>
          <FormWrapper
            title="Request Demo"
            width={isMobile ? "673px" : "550px"}
          >
            <RhombusForm
              formId={FORM_IDS.LIVE_DEMO}
              useFormExpansion
              expandFormFieldIndex={2}
              groupSliceNumber={2}
              useSecondaryButton
            />
          </FormWrapper>
        </HeroRight>
      </HeroSectionInner>
    </HeroContainer>
  );
};

export default SurveillanceHero;
