import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React, { useEffect, useRef, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import RhombusButton from "../RhombusButton";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import { useIsVisible } from "hooks/useIsVisible";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "linaria";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 5rem;
`;

const SectionTitle = styled(FancyTitle)`
  text-align: center;
`;

const ScrollContainer = styled.div`
  display: flex;
  gap: 5rem;
`;

const AnimationCol = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

const StickyCol = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 7.5rem;
  width: 100%;
`;

const Frame = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.25rem;
`;

const imageStyles = css`
  width: 700px;
  max-width: 100%;
`;

export default function AllInOnePlatformScrollSection({ rows }) {
  const [currentRow, setCurrentRow] = useState(rows[0]);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const isVisible1 = useIsVisible(ref1);
  const isVisible2 = useIsVisible(ref2);
  const isVisible3 = useIsVisible(ref3);
  const isVisible4 = useIsVisible(ref4);
  const isVisible5 = useIsVisible(ref5);

  useEffect(() => {
    if (isVisible1) {
      setCurrentRow(rows[0]);
    }
    if (isVisible2 && !isVisible1) {
      setCurrentRow(rows[1]);
    }
    if (isVisible3 && !isVisible2) {
      setCurrentRow(rows[2]);
    }
    if (isVisible4 && !isVisible3) {
      setCurrentRow(rows[3]);
    }
    if (isVisible5 && !isVisible4) {
      setCurrentRow(rows[rows.length - 1]);
    }
  }, [isVisible1, isVisible2, isVisible3, isVisible4, isVisible5]);

  const [props, api] = useSpring(() => ({}), []);

  useEffect(() => {
    api.start({
      from: { opacity: 0, transform: "translateX(-500px)" },
      to: { opacity: 1, transform: "translateX(0)" },
    });
  }, [currentRow]);

  return (
    <SectionContainer style={{ overflow: "visible" }} id="overview">
      <SectionInner>
        <ContentContainer>
          <SectionTitle>An All-in-One Platform</SectionTitle>
          <ScrollContainer>
            <AnimationCol>
              <StickyCol>
                <animated.div style={props}>
                  <Frame>
                    <TextContainer>
                      <PreTitle color="var(--blue-500)">
                        {currentRow.preTitle}
                      </PreTitle>
                      <TitleMed>{currentRow.title}</TitleMed>
                      <MainParagraph
                        dangerouslySetInnerHTML={{
                          __html: currentRow.description,
                        }}
                      />
                    </TextContainer>
                    <RhombusButton
                      title={CTA_BUTTON_COPY}
                      type="primary"
                      path="#form"
                      style={{ marginTop: "1.25rem" }}
                    />
                  </Frame>
                </animated.div>
              </StickyCol>
            </AnimationCol>
            <AnimationCol>
              <Frame ref={ref1}>
                <GatsbyImage
                  image={getImage(rows[0].image)}
                  alt={rows[0].title}
                  objectFit="contain"
                  className={imageStyles}
                />
              </Frame>
              <Frame ref={ref2}>
                <GatsbyImage
                  image={getImage(rows[1].image)}
                  alt={rows[1].title}
                  objectFit="contain"
                  className={imageStyles}
                />
              </Frame>
              <Frame ref={ref3}>
                <GatsbyImage
                  image={getImage(rows[2].image)}
                  alt={rows[2].title}
                  objectFit="contain"
                  className={imageStyles}
                />
              </Frame>
              <Frame ref={ref4}>
                <GatsbyImage
                  image={getImage(rows[3].image)}
                  alt={rows[3].title}
                  objectFit="contain"
                  className={imageStyles}
                />
              </Frame>
              <Frame ref={ref5}>
                <GatsbyImage
                  image={getImage(rows[4].image)}
                  alt={rows[4].title}
                  objectFit="contain"
                  className={imageStyles}
                />
              </Frame>
            </AnimationCol>
          </ScrollContainer>
        </ContentContainer>
      </SectionInner>
    </SectionContainer>
  );
}
