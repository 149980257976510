import React from "react";
import SurveillanceHero from "./SurveillanceHero";
import TrustedPartner from "components/common/landingPage/TrustedPartner";
import RhombusPlatformFeatures from "components/common/RhombusPlatformFeatures";
import LogoSlider from "components/common/LogoSlider";
import AllInOnePlatformScrollSection from "components/common/scroll-sections/AllInOnePlatformScrollSection";
import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import MobileScrollSection from "components/common/scroll-sections/MobileScrollSection";

export default function SurveillancePageV2() {
  const { isMobile } = useIsMobile(1024);
  const renderContent = data => {
    const ROWS = [
      {
        preTitle: "smart cameras",
        title: "Protect What Matters Most with Powerful Camera Solutions",
        description: `The Rhombus suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a <b>single pane of glass.</b>`,
        image: data.frame1,
      },
      {
        preTitle: "ai analytics",
        title: "Stay in the Know with Real-Time, AI-Powered Smart Alerts",
        description: `Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the <b>system automatically learns</b> what ‘normal’ looks like in your spaces and becomes smarter and more effective.`,
        image: data.frame2,
      },
      {
        preTitle: "Environmental monitoring",
        title: "Create Healthier Spaces and Manage Climate-Controlled Areas",
        description: `Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with <b>real-time alerts and synchronized video.</b>`,
        image: data.frame3,
      },
      {
        preTitle: "ACCESS CONTROL",
        title: "Unify Building Management Like Never Before",
        description: `Pair seamlessly with the Rhombus Platform for all-in-one security at scale. <b>Manage all aspects of your spaces</b> from a single pane of glass to streamline operations, eliminate complexity, and improve safety.`,
        image: data.frame4,
      },
      {
        preTitle: "ALARM MONITORING",
        title:
          "Protect Your Spaces Around the Clock with Professional Monitoring",
        description: `Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring that <b>combines automatic security alerts with live monitoring services.</b>`,
        image: data.frame5,
      },
    ];
    return (
      <>
        <SurveillanceHero />
        <LogoSlider bgColor="#e0f0fb" />
        {isMobile ? (
          <MobileScrollSection
            rows={ROWS}
            title={"An All-in-One Platform"}
            sectionId={"overview"}
          />
        ) : (
          <AllInOnePlatformScrollSection rows={ROWS} />
        )}
        <RhombusPlatformFeatures />
        <TrustedPartner />
      </>
    );
  };

  const GET_IMAGES = graphql`
    query {
      frame1: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/frame-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame2: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/frame-2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame3: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/frame-3.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame4: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/frame-4.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame5: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/frame-5.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
