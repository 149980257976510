import { FORM_IDS } from "components/data/landing-page-data";
import { Link } from "gatsby";
import React from "react";

const Disclaimer = ({ formId }) => {
  return formId === FORM_IDS.FREE_TRIAL ? (
    <p>
      <Link
        to="https://legal.rhombus.com/legal/product-evaluation-agreement"
        target="_blank"
      >
        *Trial terms apply.
      </Link>{" "}
      The Rhombus team decides equipment allocation & duration. Some larger
      trials may require a deposit. Rhombus does not charge for any products you
      return at the end of the trial period.
    </p>
  ) : (
    <p>
      By submitting this request, you have read and agree to our{" "}
      <Link to="/privacy/" target="_blank">
        Privacy Policy.
      </Link>
    </p>
  );
};

export default Disclaimer;
