import React, { useEffect } from "react";
import { inputContainer, inputWrap, textareaStyles } from "./styles/formStyles";

const FormTextArea = ({ field, formState, setFormState, darkMode }) => {
  useEffect(() => {
    console.log(field);
  }, []);
  return (
    <div className={inputContainer} key={field.name}>
      <div className={inputWrap}>
        {field.label && <label>{field.label}</label>}
        <textarea
          data-testid={field.name}
          onChange={e =>
            setFormState({
              ...formState,
              [field.name]: {
                objectTypeId: field.objectTypeId,
                value: e.target.value,
              },
            })
          }
          className={textareaStyles}
          rows={4}
          name={field.name}
          placeholder={field.placeholder}
          required={field.required}
          value={formState[field.name].value}
        />
      </div>
    </div>
  );
};

export default FormTextArea;
