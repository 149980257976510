import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PreTitle,
  TitleMed,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import ReviewSlider from "../sliders/ReviewSlider";
import RhombusButton from "../RhombusButton";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import { styled } from "linaria/react";

const TextCol = styled(SectionCol)`
  flex-direction: column;
`;

const TrustedPartner = () => {
  return (
    <SectionContainer id="testimonials" style={{ overflow: "visible" }}>
      <SectionInner>
        <SectionCol>
          <ReviewSlider />
        </SectionCol>
        <TextCol>
          <TextContainer>
            <PreTitle color="var(--blue-500)">
              Curated, consultative solutions for your BUSINESS
            </PreTitle>
            <TitleMed>Your Trusted Security Partner</TitleMed>
            <MainParagraph style={{ marginTop: "0.75rem" }}>
              Best-in-class physical security is just the start. Rhombus is
              committed to building lasting partnerships, innovating products
              and services through customer feedback, and quickly rolling out
              new features that will keep you ahead of the curve.
            </MainParagraph>
          </TextContainer>
          <RhombusButton
            title={CTA_BUTTON_COPY}
            type="primary"
            path="#form"
            style={{ marginTop: "1.25rem" }}
          />
        </TextCol>
      </SectionInner>
    </SectionContainer>
  );
};

export default TrustedPartner;
