import SurveillancePageV2 from "components/landing-pages/surveillance/SurveillancePageV2";
import GlobalLayout from "components/page/GlobalLayout";
import { SURVEILLANCE_LANDING_PAGE_LINKS } from "components/page/newHeader/lib/menu-data";
import React from "react";
import { Helmet } from "react-helmet";

export default function SurveillanceB() {
  return (
    <GlobalLayout
      color="#e0f0fb"
      landingPage
      customLinks={SURVEILLANCE_LANDING_PAGE_LINKS}
      includeButton
    >
      <Helmet>
        <title>Security + Surveillance Unified | Rhombus™</title>
        <meta
          name="description"
          content="Stay in the know from anywhere at anytime. Automated alerts and surveillance monitoring ensures your spaces, assets, and people are protected at all times."
        />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <SurveillancePageV2 />
    </GlobalLayout>
  );
}
