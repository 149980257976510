import { styled } from "linaria/react";
import React from "react";

const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

const StyledRadio = styled.input`
  appearance: none;
  height: 25px;
  width: 25px;
  background-color: var(--white);
  border: 1px solid var(--nuetral-200);
  border-radius: 50%;
  display: grid;
  place-content: center;
  transition: all 250ms;
  cursor: pointer;
  flex-shrink: 0;
  ::before {
    content: "";
    width: 16px;
    height: 16px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--blue-500);
    border-radius: 50%;
  }
  :checked::before {
    transform: scale(1);
  }
  :hover,
  :checked {
    border-color: var(--blue-500);
  }
`;

const OptionsContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 16px;
  label {
    flex-grow: 1;
    cursor: pointer;
    color: var(--nuetral-400);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const RadioFieldSet = ({ field, formState, setFormState, darkMode }) => {
  return (
    <FieldSet>
      <label>{field.label}</label>
      <OptionsContainer>
        {field.options.map(option => (
          <Option key={option.label}>
            <StyledRadio
              type="radio"
              id={option.label}
              name={field.name}
              value={option.value}
              checked={formState[field.name]?.value === option.value}
              required={field.required}
              onChange={e =>
                setFormState({
                  ...formState,
                  [field.name]: {
                    objectTypeId: field.objectTypeId,
                    value: e.target.value,
                  },
                })
              }
            />
            <label htmlFor={option.label}>{option.label}</label>
          </Option>
        ))}
      </OptionsContainer>
    </FieldSet>
  );
};

export default RadioFieldSet;
