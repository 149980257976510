import React from "react";
import { styled } from "linaria/react";
import Carrot from "components/rhombus-UI/theme/assets/carrot.svg";
import { StyledCheckBox } from "./BooleanCheckBox";

const StyledDetails = styled.details`
  width: 100%;
  &[open] > summary::marker {
    display: none;
    content: "";
  }
  &[open] > summary::after {
    transform: rotate(90deg);
  }
`;

const StyledSummary = styled.summary`
  color: var(--nuetral-400);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 1rem;
  ::marker {
    display: none;
    content: "";
  }
  ::after {
    content: "";
    background-image: url(${Carrot});
    background-size: contain;
    background-position: center;
    height: 20px;
    width: 20px;
    display: ${props => (props.arrow ? "inline-flex" : "none")};
    justify-content: center;
    align-items: center;
    transition: all 250ms;
  }
`;

const OptionsContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const Option = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 16px;
  width: calc(50% - 0.5rem);
  label {
    flex-grow: 1;
    cursor: pointer;
    color: var(--nuetral-400);
    padding: 1rem 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const MultiSelectCheckBoxDropDown = ({
  formState,
  setFormState,
  field,
  arrow = false,
  darkMode,
}) => {
  const handleChange = (e, field) => {
    const { value, checked } = e.target;
    const currentValue = formState[field.name]?.value || "";

    let updatedValue = checked
      ? currentValue
        ? `${currentValue};${value}`
        : value
      : currentValue
          .split(";")
          .filter(val => val !== value)
          .join(";");

    setFormState({
      ...formState,
      [field.name]: {
        objectTypeId: field.objectTypeId,
        value: updatedValue,
      },
    });
  };

  return (
    <StyledDetails open>
      <StyledSummary arrow={arrow}>{field.label}</StyledSummary>
      <OptionsContainer>
        {field.options.map(option => (
          <Option key={option.label}>
            <StyledCheckBox
              type="checkbox"
              id={option.label}
              name={option.label}
              value={option.value}
              onChange={e => handleChange(e, field)}
              required={field.required}
            />
            <label for={option.label}>{option.label}</label>
          </Option>
        ))}
      </OptionsContainer>
    </StyledDetails>
  );
};

export default MultiSelectCheckBoxDropDown;
