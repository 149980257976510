import { SectionContainer } from "components/rhombus-UI/theme/containers";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";

const SliderWrapper = styled(SectionContainer)`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: ${props => props.bgColor};
`;

const sectionInner = css`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const carouselWrapper = css`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  -webkit-mask: linear-gradient(90deg, transparent, #fff 10% 80%, transparent);
`;
const carouselInner = css`
  width: max-content;
  display: flex;
  gap: 2.5rem;
  animation: scroll 80s linear infinite;
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 1.25rem));
    }
  }
`;

const CarouselItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const logoStyles = css`
  transform: scale(0.7) !important;
`;

export default function LogoSlider({ bgColor = "var(--gradient-light)" }) {
  const renderContent = data => {
    const LOGO_SET_1 = [
      data.logo1,
      data.logo2,
      data.logo3,
      data.logo4,
      data.logo5,
      data.logo6,
      data.logo7,
      data.logo8,
      data.logo9,
      data.logo10,
      data.logo11,
      data.logo12,
      data.logo13,
      data.logo14,
      data.logo15,
    ];

    const logoRow1 = [...LOGO_SET_1, ...LOGO_SET_1];

    return (
      <SliderWrapper bgColor={bgColor}>
        <div className={sectionInner}>
          <div className={carouselWrapper}>
            <div
              className={carouselInner}
              style={{ "--width": logoRow1.length }}
            >
              {logoRow1.map((logo, index) => (
                <CarouselItem key={index}>
                  <GatsbyImage
                    image={getImage(logo)}
                    alt=""
                    objectPosition="center"
                    className={logoStyles}
                    loading="eager"
                  />
                </CarouselItem>
              ))}
            </div>
          </div>
        </div>
      </SliderWrapper>
    );
  };
  const GET_LOGOS = graphql`
    query {
      logo1: file(
        relativePath: { eq: "components/common/logos/slider/navy/goodwill.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 152, height: 25)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/slider/navy/lafs.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 136, height: 68)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/slider/navy/amazon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 116, height: 35)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/slider/navy/stryker.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 101, height: 28)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/slider/navy/praesum.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 164, height: 48)
        }
      }
      logo6: file(
        relativePath: { eq: "components/common/logos/slider/navy/clark.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 157, height: 46)
        }
      }

      logo7: file(
        relativePath: {
          eq: "components/common/logos/slider/navy/blue-apron.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 133, height: 62)
        }
      }
      logo8: file(
        relativePath: { eq: "components/common/logos/slider/navy/ca.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 94, height: 47)
        }
      }
      logo9: file(
        relativePath: { eq: "components/common/logos/slider/navy/torcon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 88, height: 59)
        }
      }
      logo10: file(
        relativePath: { eq: "components/common/logos/slider/navy/hurley.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 126, height: 72)
        }
      }
      logo11: file(
        relativePath: { eq: "components/common/logos/slider/navy/aramark.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 145, height: 41)
        }
      }
      logo12: file(
        relativePath: {
          eq: "components/common/logos/slider/navy/south-miss.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 80, height: 55)
        }
      }

      logo13: file(
        relativePath: {
          eq: "components/common/logos/slider/navy/10-federal.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 153, height: 52)
        }
      }
      logo14: file(
        relativePath: {
          eq: "components/common/logos/slider/navy/ready-spaces.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 197, height: 52)
        }
      }
      logo15: file(
        relativePath: { eq: "components/common/logos/slider/navy/middleby.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 321, height: 25)
        }
      }
    }
  `;
  return <StaticQuery query={GET_LOGOS} render={renderContent} />;
}
