import { toTitleCase } from "components/common/util/helperFunctions";
import { TitleMed } from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React from "react";

const FormWrapperContainer = styled.div`
  position: ${props => (props.sticky ? "sticky" : "unset")};
  top: 7.5rem;
  scroll-margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: ${props => props.width};
  max-width: 100%;
  border-radius: 1.25rem;
  padding: ${props => (props.darkMode ? 0 : "1.75rem")};
  background: ${props =>
    props.darkMode
      ? "transparent"
      : "linear-gradient(var(--white), var(--white)) padding-box,linear-gradient(to right, var(--blue-500), var(--teal-500)) border-box"};
  border: 1px solid transparent;
  z-index: 1;
  > h2,
  > p {
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-radius: ${props =>
      props.outline ? "1.25rem" : props.darkMode ? "0.5rem" : "unset"};
    background: ${props =>
      props.outline
        ? "linear-gradient(var(--white), var(--white)) padding-box,linear-gradient(to right, var(--blue-500), var(--teal-500)) border-box"
        : props.darkMode
        ? "transparent"
        : "var(--white)"};
    border: ${props => (props.outline ? "1px solid transparent" : "none")};
    padding: ${props => (props.darkMode ? 0 : "1.75rem 1.25rem")};
  }
`;

const FormTitle = styled(TitleMed)`
  font-size: 25px;
  @media (max-width: 820px) {
    max-width: 80%;
  }
`;

export default function FormWrapper({
  children,
  width = "550px",
  outline = false,
  title,
  darkMode = false,
  sticky = false,
  style,
}) {
  return (
    <FormWrapperContainer
      outline={outline}
      width={width}
      id="form"
      darkMode={darkMode}
      sticky={sticky}
      style={style}
    >
      {title && (
        <FormTitle color={darkMode && "var(--white)"}>
          {toTitleCase(title)}
        </FormTitle>
      )}
      {children}
    </FormWrapperContainer>
  );
}
